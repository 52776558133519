.ReactTable {
  & .rt-tbody, & .rt-table {
    overflow: visible; /* fixes https://github.com/codefordenver/Comrad/issues/546, but also makes it so the table can't have scrollbars */
  }

  & .rt-th {
    font-size: 1.8rem;

    background-color: $color-primary-lighter;
    color: white;
    outline: none;
  }

  & .rt-td {
    font-size: 1.4rem;
  }

  & .rt-thead {
    & .rt-th {
      &.-sort-asc {
        box-shadow: inset 0 3px 0 0 $color-primary;
      }
      &.-sort-desc {
        box-shadow: inset 0 -3px 0 0 $color-primary;
      }
    }
    overflow-x: hidden !important;
  }

  & .-next,
  & .-previous {
    background-color: $color-primary-lighter;

    & .-btn {
      color: white;
    }
  }

  &.clickable-rows {
    .rt-tr-group {
      cursor: pointer;
    }
  }
}
