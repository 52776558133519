/* ------------------------------------------
  COLOR
------------------------------------------ */

.red {
  color: red;
}

.primary {
  color: $color-primary;
}

.secondary {
  color: #7db2bf;
}

.dark-grey {
  color: $color-grey-4;
}

.white {
  color: #ffffff;
}

.grey-1 {
  color: #eee;
}
.grey-2 {
  color: #dbdcdd;
}
.grey-3 {
  color: #a4a9ad;
}
.grey-4 {
  color: $color-grey-4;
}

/* ------------------------------------------
  Background Colors
------------------------------------------ */

.background-color-primary {
  background-color: $color-primary;
}

.background-color-secondary {
  background-color: #7db2bf;
}

.background-color-dark-grey {
  background-color: $color-grey-4;
}

.background-color-white {
  background-color: #ffffff;
}

.background-color-grey {
  background-color: #a4a9ad;
}

.background-color-light-grey {
  background-color: #dbdcdd;
}