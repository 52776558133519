.unauthorized {
  display: flex;
  justify-content: center;
  align-items: center;
  &__h1 {
    color: $color-danger;
  }
  /* __placholder should be replaced by the alert from #218 when ready, or it may not be necessary, depending on how that gets implemented */
  &__placeholder {
    text-align: center;
  }
}
