.dashboard-container {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;

  .dhp {
    display: grid;
    order: 1;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto;
    grid-gap: 1rem;
    order: 1;
    width: 70%;
    min-height: 400px;


    &__section {
      &-1 {
        grid-column: 1 / span 2;
        grid-row: 2;
      }

      &-2 {
        grid-column: 1 / span 2;
        grid-row: 3;
      }

      &-3 {
        grid-column: 1;
        grid-row: 1;
        
      }

      &-4 {
        grid-column: 2;
        grid-row: 1;
      }

    }

    .up-next,
    .currently-on-air {
      &__host-name {
        font-size: 1.4rem;
        text-align: center;
      }

      &__show-time {
        font-size: 1.2rem;
        text-align: center;
      }
    }
  }

  .calendar-agenda-container {
    display: inline-block;
    order: 2;
    font-size: 1.2rem;
    width: calc(30% - 2.5rem);
  }
}