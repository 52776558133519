.traffic-form {
  &__grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 1rem;
    grid-row-gap: 2.5rem;

    margin-bottom: 2.5rem;
  }

  &__buttons {
    display: inline-grid;
    grid-template-columns: auto auto;
    grid-column-gap: 1rem;
    padding-bottom: 1rem;
  }

  .editor-container {
    resize: vertical;
  }
}