.SingleDatePicker {
  border-radius: $border-radius;
  border: 2px solid $color-primary-light;
  &:focus {
    outline: none;
    border: 2px solid $color-primary;
  }
}

.react-datepicker__close-icon {
  z-index: 300;
}

.react-datepicker-popper {
  z-index: 3; /* sit over the RichTextArea and input labels */
}