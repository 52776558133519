.nav-test {
  display: flex;

  border-bottom: 1px solid #000;
  &__link {
    font-size: 2rem;
    text-decoration: none;
    padding: 0 1rem;

    &:not(:last-child) {
      border-right: 1px solid #000;
    }
  }
}
