$color-primary-lighter: #8BBEC6;
$color-primary-light: #4598A4;
$color-primary: #007283;
$color-primary-dark: #005360;
$color-primary-darker: #00343C;

$color-success-lighter: #C3F1D6;
$color-success-light: #87E4AE;
$color-success: #4BD685;
$color-success-dark: #20BC62;
$color-success-darker: #178445;

$color-info-lighter: #B6E1FC;
$color-info-light: #8AD0FA;
$color-info: #5FBFF9;
$color-info-dark: #468BB6;
$color-info-darker: #2C5772;

$color-danger-lighter: #F9C5BF;
$color-danger-light: #F6A399;
$color-danger: #F38173;
$color-danger-dark: #B15E54;
$color-danger-darker: #6F3B35;

$color-warning-lighter: #FFDCB9;
$color-warning-light: #FFC78F;
$color-warning: #FFB266;
$color-warning-dark: #BA824B;
$color-warning-darker: #74512F;

$color-grey-1: #eee;
$color-grey-2: #dbdcdd;
$color-grey-3: #a4a9ad;
$color-grey-4: #555759;

$color-white: #fff;
$color-black: #000;