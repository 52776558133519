.show-list {
    h1 {
        margin-bottom: 0;
    }
    &__date-selector {
        max-width: 20rem;
    }
    &__shows {
        h2 {
            margin-top: 2rem;
        }
    }
    &__show {
        margin: 0.5rem 0;
        font-size: 1.5rem;
    }
    &__no-host {
        color: $color-grey-3;
    }
}