.track-view-page {
  display: grid;
  grid-row-gap: 1rem;
  
  font-size: 1.5rem;
}
.track-edit-button-wrapper {
    display: inline-block;
    width: max-content;
    border-radius: $border-radius;
    background-color: $color-primary;
    padding: 5px;
    transition: background-color 0.1s;
    font-size: 1.8rem;
    color: white;
    cursor: pointer;
    height: 3.8rem;
    text-align: center;
}