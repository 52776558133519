body {
  font-family: $font-family-primary;
  font-weight: 500;
  color: $color-grey-4;
  line-height: 1.7;
}

h1,
.h1 {
  color: $color-primary;
  font-family: $font-family-secondary;
  font-size: 3.4rem;
  font-weight: $font-weight-heavy;

  margin-bottom: 2rem;
}

h2,
.h2 {
  color: $color-primary-light;
  font-family: $font-family-primary;
  font-weight: $font-weight-light;
  font-size: 3rem;
  line-height: 1.15;
}

h3,
.h3 {
  color: $color-primary-light;
  font-family: $font-family-primary;
  font-weight: $font-weight-light;
  font-size: 2.6rem;
  line-height: 1.15;

  margin-bottom: 1.4rem;
}

h4,
.h4 {
  color: $color-primary-light;
  font-family: $font-family-primary;
  font-weight: $font-weight-light;
  font-size: 2.2rem;
  line-height: 1.15;

  margin-bottom: 0.4rem;
}

h5,
.h5 {
  color: $color-primary-light;
  font-family: $font-family-primary;
  font-weight: $font-weight-light;
  font-size: 1.8rem;
  line-height: 1.3;
}

p,
.p {
  font-size: 1.6rem;
  color: $color-grey-4;

  margin-top: 1rem;
  margin-bottom: 1rem;
}

a,
.a {
  text-decoration: none;
  font-family: inherit;
  font-size: inherit;

  &:hover {
    background-color: none;
  }
}
