.form-group {
    position: relative;
    
    &--inline {
      display: inline; 
      margin-right: 0.5rem;
      
      .textarea {
        width: auto;
        display: inline;
      } 
    }
  }
  
  .textarea {
    font-size: 1.8rem;
    font-family: inherit;
    color: $color-primary;
    background: none;
    background-clip: padding-box;
    border: 2px solid $color-primary-light;
    border-radius: $border-radius;
    display: block;
    height: 12rem;
    padding: 1rem;
    padding-left: 1.4rem;
    position: relative;
    width: 100%;
    z-index: 1;
    resize: none;
    
    transition: all 0.2s;
  
    &:focus {
      outline: none;
      border: 2px solid $color-primary;
    }
  
    &.error,
    &.invalid {
      border-color: $color-danger;
    }
  
    &__error {
      color: $color-danger;
      font-size: 1.2;
  
      margin-top: 3px;
      padding-left: 1.2rem;
      position: absolute;
      transition: all .2s;
    }
  
    &__label {
      background: $color-white;
      color: $color-grey-3;
      cursor: text;
      font-size: 1.8rem;
      padding: 0 1rem;
      position: absolute;
      top: 0.4rem;
      left: 0.6rem;
  
      transform-origin: 0% 100%;
      transition: all 0.2s;
  
      &.active,
      &:active,
      &.dirty,
      &.focus,
      &:focus {
        color: $color-primary;
        transform: scale(0.6) translate(0.6rem, -4rem);
        z-index: 2;
      }
  
      &.error {
        color: $color-danger;
      }
    }
  }
  
  .icon {
    color: $color-primary;
    height: 2rem;
    width: 2rem;
  
    font-size: 2rem;
  
    position: absolute;
    top: 0.8rem;
    right:1.5rem;
  }
  