.sidebar {
  background-color: $color-primary-light;
  height: 100%;

  &__list {
  }

  &__item {
  }

  &__link {
    text-decoration: none;

    display: grid;

    &:hover {
      background-color: $color-primary-lighter;
    }
  }

  &__icon {
    font-size: 2.5rem;
    color: $color-white;
    margin: 2.2rem 0;

    justify-self: center;
  }
}
