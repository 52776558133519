  
  /* ------------------------------------------
    TEXT ALIGN
  ------------------------------------------ */
  .text-left {
    text-align: left;
  }
  
  .text-center {
    text-align: center;
  }
  
  .text-right {
    text-align: right;
  }
  
  /* ------------------------------------------
    Font Style
  ------------------------------------------ */
  
  .bold{
    font-weight: bold;
  }
  
  .italic{
    font-style: italic;
  }
  
  .oblique{
    font-style: oblique;
  }
  
  .overline{
    text-decoration: overline;
  }
  
  .line-through{
    text-decoration: line-through;
  }
  
  .underline{
    text-decoration: underline;
  }
  
  /* ------------------------------------------
    Border
  ------------------------------------------ */
  
  border-shadow-bottom-right{
      box-shadow: 2px 2px 2px -1px #DBDCDD;
  }
  
  border-shadow-all{
      box-shadow:0px 0px 2px 2px #DBDCDD;
  }
  
  border-dark-grey{
    border-color: $color-grey-4;
  }
  
  border-grey{
    border-color: #A4A9AD
  }
  
  border-light-grey{
    border-color: #DBDCDD
  }
  
  border-left{
    border-left: 1px
  }
  
  border-right{
    border-right: 1px
  }
  
  border-top{
    border-top: 1px
  }
  
  border-bottom{
    border-bottom: 1px
  }
  
  border-all{
    border: 1px;
  }
  
  no-border-top{
    border-top: none;
  }
  
  no-border-right{
    border-right: none;
  }
  
  no-border-left{
    border-left: none;
  }
  
  no-border-bottom{
    border-bottom: none;
  }
  
  no-border{
    border: none;
  }
  
  /* ------------------------------------------
    Background Colors
  ------------------------------------------ */
  
  .background-color-primary{
    background-color: $color-primary;  
  }
  
  .background-color-secondary{
    background-color: #7DB2BF
  }
  
  .background-color-dark-grey{
    background-color: $color-grey-4;
  }
  
  .background-color-white{
    background-color: #ffffff
  }
  
  .background-color-grey{
    background-color: #A4A9AD
  }
  
  .background-color-light-grey{
    background-color: #DBDCDD
  }
  
  /* ------------------------------------------
    Display
  ------------------------------------------ */
  .inline{
    display: inline;
  }
  
  .block{
    display: block;
  }
  
  .inline-block{
    display: inline-block;
  }
  
  .display-none{
    display: none;
  }
  
  /* ------------------------------------------
    Float
  ------------------------------------------ */
  
  .float-left{
    float: left;
  }
  
  .float-right{
    float: right;
  }
  
  /* ------------------------------------------
    Position
  ------------------------------------------ */
  
  .position-static{
    position: static;
  }
  
  .position-relative{
    position: relative;
  }
  
  .position-fixed{
    position: fixed;
  }
  
  .position-absolute{
    position: absolute;
  }
  
  .position-sticky{
    position: sticky;
  }
  
  /* ------------------------------------------
    vertical-align
  ------------------------------------------ */
  
  .va-baseline{
      vertical-align: baseline;
  }
  
  .va-top{
      vertical-align: top;
  }
  
  .va-bottom{
      vertical-align: bottom;
  }
  
  .va-sub{
      vertical-align: sub;
  }
  
  .va-super{
      vertical-align: super;
  }
  
  .va-middle{
      vertical-align: middle;
  }
  
  /* ------------------------------------------
    Visibility
  ------------------------------------------ */
  
  .hidden{
      visibility: hidden;
  }
  
  /* ------------------------------------------
    Lists
  ------------------------------------------ */
  
  .circle-list{
    list-style-type: circle;
  }
  
  .square-list{
    list-style-type: square;
  }
  
  .disc-list{
    list-style-type: disc;
  }
  
  .upper-numeral-list{
    list-style-type: upper-roman;
  }
  
  .lower-numeral-list{
    list-style-type: lower-roman;
  }
  
  .lower-letter-list{
    list-style-type: lower-alpha;
  }
  
  .upper-letter-list{
    list-style-type: upper-alpha;
  }
  
  .inside-list{
    list-style-position: inside;
  }
  
  .outside-list{
    list-style-position: outside;
  }
  
  /* ------------------------------------------
    Border
  ------------------------------------------ */
  
  border-table-collapse{
    border-collapse: collapse;
  }
  
  /* ------------------------------------------
    Overflow
  ------------------------------------------ */
  
  .overflow-visible{
    overflow: visible;
  }
  
  .overflow-hidden{
    overflow: hidden;
  }
  
  .overflow-scroll{
    overflow: scroll;
  }
  
  .overflow-auto{
    overflow: auto;
  }
  
  /* ------------------------------------------
    Opacity
  ------------------------------------------ */
  
  .opacity-1{
    opacity: 1;
  }
  
  .opacity-point6{
    opacity: 0.6;
  }
  
  .opacity-point3{
    opacity: 0.3;
  }
  
  .opacity-point1{
    opacity: 0.1;
  }
  
  /* ------------------------------------------
    Clearfix
  ------------------------------------------ */
  
  
  .clearfix::after {
    content: "";
    clear: both;
    display: table;
  }