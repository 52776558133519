.footer {
  border-top: 3px solid #007083;
  padding: 1rem;
  width: 100%;
}

.footer__logo {
  display: grid;
  justify-items: center;
 }

 .footer__img {
   height: 3rem;
 }