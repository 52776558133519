.alert {
  display: grid;
  grid-template-columns: 5rem auto;
  grid-column-gap: 0.5rem;
  position: relative;

  border-radius: $border-radius;
  color: white;
  padding: 0.5rem;
  margin-bottom: 1rem;

  &--active {
    display: grid;
  }

  &--inactive {
    display: none;
  }

  &__times {
    position: absolute;
    top: 0.5rem;
    right: 1.5rem;

    font-size: 2rem;

    cursor: pointer;
    height: 1rem;
    width: 1rem;
  }

  &__icon {
    grid-column: 1;

    display: grid;
    justify-items: center;
    align-items: center;

    font-size: 3.6rem;

    padding: 0.5rem;
  }

  &__container {
    grid-column: 2;

    padding: 0.5rem;
  }

  &__header {
    font-weight: bold;
    font-size: 2.2rem;
  }

  &__body {
    font-size: 1.6rem;
  }

  &--info {
    background-color: $color-info;
  }
  &--success {
    background-color: $color-success-dark;
  }
  &--danger {
    background-color: $color-danger;
  }
  &--warning {
    background-color: $color-warning;
  }
}
