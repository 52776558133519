*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  font-size: 62.5%;
  -webkit-text-size-adjust: 100%; 
}

body {
  box-sizing: border-box;
}

button,
input {
  overflow: visible;
}