.Filter {
  display: inline;

  &__text {
    color: $color-primary-light;
    text-transform: uppercase;

    cursor: pointer;
    padding: 0 4px 2px;
  }

  &__radio {
    position: absolute;
    opacity: 0;
    cursor: pointer;

    &:checked + .Filter__text {
      border-bottom: 2px solid $color-primary-light;
    }
  }

  // &__item {
  //   color: $color-primary-light;
  //   cursor: pointer;
  //   display: inline;

  //   margin-right: 1rem;
  //   padding: 0 4px 2px;

  //   &.active {
  //     border-bottom: 2px solid $color-primary-light;
  //   }
  // }
}
