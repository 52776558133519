.search-term {
  color: $color-grey-4;
  font-family: $font-family-primary;
  font-size: 1.4rem;
  padding: 0.6rem 0;

  &__term {
    font-weight: $font-weight-heavy;
    margin-right: 0.5rem;
  }
}