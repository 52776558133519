.card--show-builder {
  position: relative;
  
  &__saving-overlay {
    z-index: 3; /* sit over label on Input components */
    position: absolute;
    background: rgba(200,200,200,0.6);
    cursor: wait;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

.show-builder {
  $show-builder-height: 95vh;
  $top-row-height: 7rem;
  
  height: $show-builder-height;
  font-size: 1.5rem;
  
  &__top-row {
    display: flex;
    height: $top-row-height;
    
    > div {
      width: 33%;
      padding: 0 1rem;
      
      &:nth-child(1) {
        text-align: left;
      }
      &:nth-child(2) {
        text-align: center;
        font-weight: bold;
        
        .edit-show-description {
          font-weight: normal;
          
          span {
            color: blue;
            cursor: pointer;
          }
        }
      }
      &:nth-child(3) {
        text-align: right;
      }
    }
  }
  
  &__grid {
    display: grid;
    grid-template-columns: calc(50% - 2rem) calc(50% - 2rem);
    grid-template-rows: auto min-content;
    grid-gap: 2rem;
    height: calc(#{$show-builder-height} - #{$top-row-height});
    
    > div {
      min-height: 20rem;
    }
    
    &__saved-items {
      grid-column: 2;
      grid-row: 1 / span 2;
      height: calc(95vh - 7rem);
    }
  }
  
  .library-tab-container {
  
    &__tabs {
      display: flex;
      border-bottom: 1px solid $color-grey-3;
      
      > div:not(.library-tab-container__voice-break-button) {
        padding: 0;
        background: $color-grey-1;
        border: 1px solid $color-grey-3;
        border-bottom: 0px;
        border-top-right-radius: 3px;
        border-top-left-radius: 3px;
        position: relative;
        padding: 1rem 2rem;
        margin-right: 1px;
        cursor: pointer;
        
        &.active {
          background: transparent;
          
          &::after { /* content to hide the bottom border */
            content: "";
            background: #fff;
            position: absolute;
            height: 1px;
            bottom: -1px;
            left: 0px;
            right: 0px;
          }
        }
      }
    }
    &__voice-break-button {
      text-align: right;
      flex-grow: 1;
    }
    &__tab-content {
      min-height: 10rem;
      border: 1px solid $color-grey-3;
      border-top: 0px;
      
      form {
        padding: 1rem;
      }
    }
  }
  
  .library-results {
    height: 28vh;
    overflow-y: scroll;
    
    .dropdown__icon {
      color: $color-grey-3;
    }
    .library-results-table td {
      vertical-align: top;
    }
    &__add-new-track {
      color: blue;
      cursor: pointer;
      display: block;
      padding: 1rem;
    }
    &__no-results {
      padding: 0 1rem 1rem;
      
      .library-results__add-new-track {
        display: inline;
        padding: 0;
      }
      em {
        font-style: italic;
      }
    }
  }
  
  
}

.add-track-modal {
  display: flex;
  > div {
    margin-left: 5rem;
  }
  > div:first-child {
    margin-left: 0;
  }
  
  .form-track-add {
    max-width: 100%;
    width: 60rem;
  }
}

.prompt-for-label-modal {
  font-size: 1.5rem;
  max-width: 300px;
  
  > div:first-child {
    margin-bottom: 1em;
  }
  
  i {
    font-style: italic;
  }
}