.main-layout {
  display: grid;
  grid-template-columns: 7.6rem auto;
  grid-template-rows: auto minmax(100vh, auto) auto;
  grid-template-areas:
    "navbar navbar"
    "sidebar body"
    "sidebar footer";

    &__navbar {
      grid-area: navbar;
    }

    &__sidebar {
      grid-area: sidebar;
    }

    &__body {
      grid-area: body;

      background-color: #eee;
      padding: 4rem;
    }

    &__footer {
      grid-area: footer;
    }
}