.calendar-agenda {
  &__grid {
    margin-top: 0.4rem;
    display: grid;
    grid-template-columns: auto auto;
    grid-gap: 0.3rem;
    text-align: left;
    color: $color-grey-4;
    
    &__show-time {
      font-size: 1.4rem;
    }
    
    &__show-title {
      font-size: 1.4rem;
      color: #000;
    }
    
    &__day-header {
      color: $color-primary;
      grid-column: span 2;
      margin-top: 0.6rem;
      margin-bottom: 0.6rem;
      font-size: 1.6rem;
      text-align: center;
    }
  }
  
  .load-more-button {
    left: 0;
    right: 0;
    margin: auto;
    margin-top: 5px;
    width: max-content;
  }
}