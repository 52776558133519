.library-search {
  display: grid;
  grid-template-rows: 1fr;
  grid-row-gap: 1rem;

  &__header {
    display: grid;
    grid-template-columns: 50rem 1fr;
    grid-column-gap: 1rem;

    &:not(:last-child) {
      margin-bottom: 3rem;
    }
  }

  &__form {
    display: grid;
    grid-template-columns: repeat(3, auto);
    grid-column-gap: 1rem;
  }
  
  &__filters {
    margin-top: 12px;
    
    > span {
      cursor: pointer;
      margin-right: 10px;
      padding-left: 4px;
      padding-right: 4px;
      padding-bottom: 2px;
      color: $color-primary-light;
      &.active {
        border-bottom: 2px solid $color-primary-light;
      }
    }
  }

  &__term {
    height: 5rem;
  }
  
  &__grid {
    &__secondary-text {
      color: $color-grey-3;
    }

    &__edit-options {
      overflow: visible !important;
      padding-right: 1.5rem !important;

      .dropdown__list {
        min-width: 6rem;
      }
      .dropdown__icon {
        color: $color-grey-3;
      }
    }
  }

  &__delete-modal, &__delete-success-modal {
    width: 48rem;
    font-size: 2rem;

    div {
      margin-top: 2rem;
    }

    i {
      font-style: italic;
    }
  }

  &__scratchpad-modal {
    width: 48rem;
    font-size: 2rem;

    div {
      margin-top: 2rem;
    }

    i {
      font-style: italic;
    }
  }
}