.calendar {
  display: grid;
  grid-template-columns: 20rem auto;
  grid-column-gap: 2rem;
  
  &__sidebar {
    /* 
      START - override react-dates styles 
    */
    .DayPicker__horizontal,.CalendarMonth, .CalendarMonthGrid {
      background: none;
    }
    .DayPicker_transitionContainer {
      width: 200px !important;
    }
    .DayPicker_weekHeaders__horizontal {
      margin-left: 0px;
    }
    .DayPickerNavigation_leftButton__horizontalDefault,.CalendarMonthGrid__horizontal {
      left: 0px;
    }
    .DayPickerNavigation_rightButton__horizontalDefault {
      right: 16px;
    }
    .DayPickerNavigation_leftButton__horizontalDefault,.DayPickerNavigation_rightButton__horizontalDefault {
      top: -4px;
    }
    .DayPickerNavigation_button {
      background: none;
      border: none;
    }
    .CalendarMonth_caption {
      font-size: 15px;
      padding-top: 0px;
    }
    .DayPicker_weekHeader {
      top: 37px;
    }
    .CalendarDay__default,.DayPicker_weekHeader_li {
      width: 28px;
      height: 28px !important;
      line-height: 28px;
    }
    .CalendarDay__default {
      border: 0;
      background: none;
      border-radius: 14px;
    }
    .CalendarDay__today {
      font-weight: 700;
    }
    .CalendarDay__selected {
      background: $color-primary;
    }
    /* 
      END - override react-dates styles 
    */
  }
}
.add-button-wrapper {
  display: inline-block;
  position: relative;
  width: max-content;
}

.calendar-add-button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $color-primary;
  color: white;
  cursor: pointer;
  height: 3.8rem;
  transition: background-color 0.1s;
  border-radius: $border-radius;
  width: max-content;
  margin-bottom: 10px;

  &-text {
    font-size: 1.8rem;
    border-right: 1px solid rgba(0, 0, 0, 0.3);
    padding: 0 1rem;
  }
  & .fas {
    padding: 0 1rem;
    display: inline-block;
  }
  
}