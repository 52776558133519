.user-profile-page {
  &__status {
    font-size: 2.2rem;
    font-weight: bold;

    &--true {
      color: $color-success;
    }

    &--false {
      color: $color-danger;
    }
  }
  
  .profile-img {
    margin-bottom: 1rem;
    text-align: center;
    
    .fas::before {
      font-size: 10rem;
    }
  }

  &__api-key-header {
    margin-top: 20px;
    margin-bottom: 10px;
  }
  
  .api-button {
    text-align: center;
  }

  &__past-future-shows {
    padding: 15px;
  }

  &__shows-headings {
    text-align: center;
  }
  
  .show-list-for-user {
    font-size: 1.6rem;
  }
}

.confirm-user-delete-modal {

  font-size: 2rem;
  
  i {
    font-style: italic;
  }
}
