.show-builder-item-list {
  overflow: auto;
  max-height: calc(100% - 2.35rem); /* 2.35rem is for the h5 above this in the html: 1.8rem font size * 1.3 line height */

  .show-builder-item {
    &.show-builder-item--announcement {
      background-color: green; 
      border-color: green; 
      color: white;
    }
    &.show-builder-item--comment {
      background-color: #BBBBBB; 
      border-color: #BBBBBB; 
      color: white;
    }
    &.show-builder-item--feature {
      background-color: royalblue; 
      border-color: royalblue; 
      color: white;
    }
    &.show-builder-item--giveaway {
      background-color: darkgoldenrod; 
      border-color: darkgoldenrod; 
      color: white;
    }
    &.show-builder-item--legalid {
      background-color: indigo; 
      border-color: indigo; 
      color: white;
    }
    &.show-builder-item--psa {
      background-color: darkorange; 
      border-color: darkorange; 
      color: white;
    }
    &.show-builder-item--track {
      background-color: #2694E8; 
      border-color: #2694E8; 
      color: white;
    }
    &.show-builder-item--underwriting { 
      background-color: slategray; 
      border-color: slategray; 
      color: white;
    }
    &.show-builder-item--voice_break {
      background-color: #BBBBBB; 
      border-color: #BBBBBB; 
      color: white;
    }
    border: 1px solid #333;
    border-radius: 4px;
    padding: 4px;
    margin-bottom: 2px;
    overflow: hidden;
    
    &__title-row {
      display: flex;
      align-items: center;
      
      &--expandable {
        cursor: pointer;
      }
      
      &__right-buttons {
        margin-left: auto;
        width: 7.5em;
        flex-grow: 0;
        flex-shrink: 0;
        
        &--one-button {
          width: auto;
        }
      }
      
      button {
        width: 3.3em;
        margin-left: 0.2em;
        
        &.button--to-scratchpad {
          margin-left: 0em;
          margin-right: 0.2em;
        }
      }
    }
    
    &__details {
      background: rgba(255,255,255,0.8);
      color: $color-grey-4;
      border-radius: 4px;
      padding: 0.2em 0.5em;
      margin-top: 0.3em;
      display: none;
      position: relative;
      
      &--expanded {
        display: block;
      }

      .fa-copy {
        position: absolute;
        right: 0;
        top: 0;
        padding: 5px;
        cursor: pointer;
      }
      
      b {
        font-weight: bold;
      }
      
      i {
        font-style: italic;
      }
      
    }
    
    &--dragging {
      opacity: 0.5;
    }
  }
}