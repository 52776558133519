.form-user {
  &__contact-info,
  &__password {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 1rem;
  }
  
  &__role-list {
    li {
      display: flex;
      margin-bottom: 1em;
      
      h3 {
        margin-bottom: 0;
        margin-right: 0.3em;
      }
      
      > div {
        margin-right: 1em;
      }
    }
  }
}
