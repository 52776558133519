.aap {
  &__album-name {
    margin-bottom: 20px;
  }

  &__form {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
}
