.form-host-group-edit {
  display: grid;
  grid-template-columns: 100%;
  grid-row-gap: 2rem;
  
  &__buttons {
    text-align: right;
    
    button {
      margin-left: 1rem;
    }
  }
  
  &__host-list {
    li {
      display: flex;
      margin-bottom: 1em;
      
      h3 {
        margin-bottom: 0;
        margin-right: 0.3em;
      }
      
      > div {
        margin-right: 1em;
      }
    }
  }
  
}