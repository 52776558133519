.dropdown__list--host-list {
  border-color: $color-grey-2;
  
  .dropdown__item--grey {
    color: $color-grey-3;
  }
}
.host-field {

  &__add-modal {
    width: 48rem;
    display: block;
    color: $color-primary;
  }
}