.Table {
  &__status {
    &.active {
      color: $color-success;
    }

    &.inactive {
      color: $color-danger;
    }
  }
}
