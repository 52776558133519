.select {
  font-size: 1.8rem;
  font-family: inherit;
  color: $color-primary;

  background: none;
  background-clip: padding-box;
  border: 2px solid $color-primary-light;
  border-radius: $border-radius;
  display: block;
  height: 3.8rem;
  padding: 0.5rem;
  padding-left: 1.4rem;
  position: relative;
  width: 100%;
  z-index: 1;

  transition: outline 0.2s border 0.2s;

  &:focus {
    outline: none;
    border: 2px solid $color-primary;
  }

  &:disabled {
    opacity: 0.5;
  }

  &.error,
  &.invalid {
    border-color: $color-danger;
  }
}
