.button {
  font-family: $font-family-primary;
  font-weight: $font-weight-normal;
  letter-spacing: 1px;
  text-align: center;
  text-transform: uppercase;
  user-select: none;

  border: $border-width solid transparent;
  border-radius: $border-radius;
  cursor: pointer;
  display: inline-block;
  transition: background-color 0.1s box-shadow 0.1s;
  text-decoration: none;
  
  &--link {
    color: $color-primary;
    font-size: 1.8rem;
    line-height: 1;
    text-transform: none;
    
    border: none;
    border-bottom: 1px solid $color-primary;
    border-radius: 0;
    height: 2.2rem;
    margin: 0;
    padding: 0 0 1px;
    // text-decoration: underline;

    &:active,
    &.active,
    &:hover,
    &.hover {
      color: $color-primary-dark;
      border-bottom: 1px solid $color-primary-dark;
    }

    &:disabled,
    &.disabled {
      color: $color-primary-lighter;
      border-bottom: 1px solid $color-primary-lighter;
    }
  }

  &--normal:not(.button--link) {
    font-size: 1.6rem;
    line-height: 3.4rem;
    height: 3.8rem;
    padding: 0 2rem;
  }

  &--small:not(.button--link) {
    font-size: 1.2rem;
    height: 3rem;
    padding: 0 1.4rem;
  }

  &:active,
  &.active,
  &:focus,
  &.focus {
    outline: none;
  }

  &:disabled,
  &.disabled {
    cursor: default;
    opacity: 0.5;
  }

  &--primary {
    color: $color-white;
    background-color: $color-primary;

    &:active,
    &.active {
      box-shadow: 0 0 0 $border-width $color-primary-light;
    }

    &:hover,
    &.hover,
    &:active,
    &.active {
      background-color: $color-primary-dark;
    }

    &:disabled,
    &.disabled {
      background-color: $color-primary-lighter;
    }
  }

  &--success {
    color: $color-white;
    background-color: $color-success;

    &:active,
    &.active {
      box-shadow: 0 0 0 $border-width $color-success-light;
    }

    &:hover,
    &.hover,
    &:active,
    &.active {
      background-color: $color-success-dark;
    }

    &:disabled,
    &.disabled {
      background-color: $color-success-lighter;
    }
  }
  
  &--neutral {
    color: $color-white;
    background-color: $color-grey-3;

    &:active,
    &.active {
      box-shadow: 0 0 0 $border-width $color-grey-1;
    }

    &:hover,
    &.hover,
    &:active,
    &.active {
      background-color: $color-grey-4;
    }

    &:disabled,
    &.disabled {
      color: $color-grey-2;
    }
  }

  &--info {
    color: $color-white;
    background-color: $color-info;

    &:active,
    &.active {
      box-shadow: 0 0 0 $border-width $color-info-light;
    }

    &:hover,
    &.hover,
    &:active,
    &.active {
      background-color: $color-info-dark;
    }

    &:disabled,
    &.disabled {
      background-color: $color-info-lighter;
    }
  }

  &--danger {
    color: $color-white;
    background-color: $color-danger;

    &:active,
    &.active {
      box-shadow: 0 0 0 $border-width $color-danger-light;
    }

    &:hover,
    &.hover,
    &:active,
    &.active {
      background-color: $color-danger-dark;
    }

    &:disabled,
    &.disabled {
      background-color: $color-danger-lighter;
    }
  }

  &--warning {
    color: $color-white;
    background-color: $color-warning;

    &:active,
    &.active {
      box-shadow: 0 0 0 $border-width $color-warning-light;
    }

    &:active,
    &.active, 
    &:hover,
    &.hover {
      background-color: $color-warning-dark;
    }

    &:disabled,
    &.disabled {
      background-color: $color-warning-lighter;
    }
  }

  
}