.user-search {
  display: grid;
  grid-template-rows: 1fr;
  grid-row-gap: 1rem;  
  
  &__grid {
        &__secondary-text {
          color: $color-grey-3;
        }
    
        &__edit-options {
          overflow: visible !important;
          padding-right: 1.5rem !important;
    
          .dropdown__list {
            min-width: 6rem;
          }
          .dropdown__icon {
            color: $color-grey-3;
          }
        }
      }
}