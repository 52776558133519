/* ------------------------------------------
  TEXT ALIGN
------------------------------------------ */
.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}


/* ------------------------------------------
  FONT SIZE
------------------------------------------ */
.fs-10 {
  font-size: 1rem;
}

.fs-20 {
  font-size: 2rem;
}

.fs-30 {
  font-size: 3rem;
}

.fs-40 {
  font-size: 4rem;
}

.fs-50 {
  font-size: 5rem;
}

/* ------------------------------------------
  FONT STYLE
------------------------------------------ */

.bold {
  font-weight: bold;
}

.italic {
  font-style: italic;
}

.oblique {
  font-style: oblique;
}

.overline {
  text-decoration: overline;
}

.line-through {
  text-decoration: line-through;
}

.underline {
  text-decoration: underline;
}