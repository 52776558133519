$show_start_z: 200;

.new-show-form,
.edit-show-form {
  min-width: 520px; /* so there is a minimum width to the form when only the show description is there in Show Builder */

  &__grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 1rem;
    grid-row-gap: 2.5rem;

    margin-bottom: 2.5rem;
  }

  &__buttons {
    display: inline-grid;
    grid-template-columns: auto auto;
    grid-column-gap: 1rem;
    padding-bottom: 1rem;
  }
  &__guest-list {
    li {
      display: flex;
      margin-bottom: 1em;
      
      h3 {
        margin-bottom: 0;
        margin-right: 0.3em;
      }
      
      > div {
        margin-right: 1em;
      }
    }
  }
}

.grid-span {
  &--2 {
    grid-column: span 2;
  }
}

.grid-column-start {
  &--1 {
    grid-column-start: 1;
  }
}

.z-index--250 {
  z-index: 250 !important;
}

.z-index--200 {
  z-index: 200 !important;
}

.z-index--150 {
  z-index: 150 !important;
}

.z-index--100 {
  z-index: 100 !important;
}

.rbc-calendar {
  min-height: 80vh !important;
}

.rbc-event {
  background-color: $color-primary;
  border-width: 1 !important;
  border-color: black !important;
}

.event-series--dev-environment-only-style {
  background-color: $color-primary;
  border-width: 1 !important;
  border-color: black !important;
}

.event-instance--dev-environment-only-style {
  background-color: #ed553b;
  border-width: 1 !important;
  border-color: black !important;
}

.event-regular--dev-environment-only-style {
  background-color: green;
  border-width: 1 !important;
  border-color: black !important;
}

.rbc-event.rbc-selected {
  background-color: $color-primary !important;
  border-color: $color-grey-2 !important;
}

.rbc-selected,.event-instance--dev-environment-only-style {
  background-color: #ed553b !important;
}

.rbc-selected,.event-regular--dev-environment-only-style {
  background-color: green !important;
}

.event__tooltip__title {
  color: $color-primary;
  font-size: 2rem;
  margin-bottom: 1rem;
}

.event__tooltip__commands {
  display: flex;
  
  > div {
    width: 50%;
    padding-right: 1%;
  }
}

.event__tooltip__edit,
.event__tooltip__delete {
  color: $color-primary;
  cursor: pointer;
  margin-top: 0.2em;
}

.event__tooltip__delete {
  color: $color-danger;
}

.not_done {
  color: pink;
}
