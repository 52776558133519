.reporting-layout {
  display: grid;
  grid-template-columns: 30rem 1fr;
  grid-template-rows: auto auto;
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;

  &__header {
    grid-column: 1 / span 2;
  }
  
  &__content {
    h2, h4 { 
      margin-bottom: 1rem;
    }
  }
}
