.dropdown {
  display: inline-block;
  position: relative;
  width: max-content;
  text-align: left;

  &__button {
    display: flex;
    align-items: center;
    justify-content: center;

    color: white;

    background-color: $color-primary;
    border-radius: $border-radius;
    cursor: pointer;
    height: 3.8rem;

    transition: background-color 0.1s;

    &-text {
      font-size: 1.8rem;

      border-right: 1px solid rgba(0, 0, 0, 0.3);
      padding: 0 1rem;
    }

    & .fas {
      padding: 0 1rem;
    }

    &.active,
    &:hover {
      background-color: $color-primary-dark;
    }
  }

  &__icon {
    color: white;
    cursor: pointer;
    font-size: 2.5rem;
    width: 100%;

    i {
      display: block;
      padding: 0px 3px;
    }
  }

  &__list {
    border: 2px solid $color-primary-light;
    border-radius: $border-radius;
    background-color: white;
    opacity: 0;
    position: absolute;
    min-width: 20rem;
    transform: translateY(-3px);
    z-index: -1;

    transition: all 0.2s;

    &.active {
      display: block;
      color: $color-primary;
      opacity: 1;
      transform: translateY(0);
      z-index: 10;
    }
  }

  &__item {
    color: $color-primary-light;
    font-size: 1.4rem;
    display: block;
    cursor: pointer;
    padding: 0.5rem 1rem;

    &:hover,
    &.selected {
      background-color: $color-grey-1;
    }
    
    &--no-results {
      color: $color-grey-4;
      font-style: italic;
    }
  }

  & .bottom-left {
    margin-top: 4px;
    right: 0px;
  }

  & .bottom-right {
    margin-top: 4px;
  }

  & .top-left {
    bottom: 0px;
    margin-bottom: 4.2rem;
    right: 0px;
  }

  & .top-right {
    bottom: 0px;
    margin-bottom: 4.2rem;
  }

  & .right-centered {
    position: absolute;
    margin-left: calc(100% + 0.5rem);
    top: 50%;
    transform: translate(-3px, -50%);

    &.active {
      transform: translate(0px, -50%);
    }
  }

  & .right-top {
    margin-left: calc(100% + 0.5rem);
    top: 0rem;
    left: 0rem;
    transform: translateX(-3px);

    &.active {
      transform: translateY(0);
    }
  }
}
