.modal-main {
  max-height: 95vh;
  overflow: auto;
  position: fixed;
  background-color: white;
  width: auto;
  height: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 1;
  z-index: 10;
  border: 2px solid $color-primary-light;
  border-radius: $border-radius;
  padding: 1rem;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 1071; /* display above .rc-tooltip */
}
