.album-view-page {
  display: grid;
  grid-row-gap: 1rem;
  font-size: 1.5rem;
  
  &__header {
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-items: space-between;
  }

  &__song-name {
    margin-bottom: 0rem;
  }

  &__last-updated {
    justify-self: end;
    margin-top: 1.4rem;
  }
  
  .edit-album-button {
    display: inline-block;
    position: relative;
    width: max-content;
    padding: 5px;
    background-color: $color-primary;
    color: white;
    cursor: pointer;
    height: 3.8rem;
    transition: background-color 0.1s;
    font-size: 1.8rem;
    border-radius: $border-radius;  
  }
  
  .tracks-header {
    display: grid;
    grid-template-columns: auto 1fr;
    grid-column-gap: 0.6rem;
    &__add {
      align-self: center;
    }
  }

}

