.artist-view {
  display: grid;
  grid-row-gap: 1rem;

  &__header {
    display: grid;
    grid-template-areas:
      'name type'
      'name last-updated';
  }

  &__last-updated {
    font-size: 1.2rem;
    grid-area: last-updated;
  }

  &__name {
    grid-area: name;
  }

  &__type {
    font-size: 2rem;
    grid-area: type;
  }

  &__last-updated,
  &__type {
    color: $color-primary;
    justify-self: right;
  }

  &__header2 {
    display: grid;
    grid-template-columns: auto 1fr;
    grid-column-gap: 0.6rem;
  }

  &__add-album2 {
    align-self: center;
    justify-content: start;
    padding-top: 0.6rem;
  }
}

.add-album-button {
  display: inline-block;
  position: relative;
  width: max-content;
  padding: 5px;
  background-color: $color-primary;
  color: white;
  cursor: pointer;
  height: 3.8rem;
  transition: background-color 0.1s;
  font-size: 1.8rem;
  border-radius: $border-radius;
}
